<template>
  <div>
    <v-row>
      <v-col cols="5" class="ml-4">
        <v-text-field
          lable="Name"
          v-model="checklist.name"
          :disabled="disabled"
          color="primary"
        ></v-text-field>
      </v-col>
      <v-col cols="5">
        <keep-alive>
          <checklist-boat-selector
            :checklist="checklist"
            @new:boat="handleNewBoat"
            :disabled="disabled || boatDisabled"
            :key="checklistIndex"
            v-if="showBoat"
          ></checklist-boat-selector>
        </keep-alive>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="1" class="mr-2">
        <checklist-toolbar
          @action="handleToolbarAction"
          :preview="!disabled && !toolbarDisabled"
        ></checklist-toolbar>
      </v-col>
    </v-row>

    <div v-if="showServicingPartner">
      <v-row no-gutters>
        <v-col cols="10" class="ml-4">
          <task-dealer-selector
            :label="'Servicing Partner'"
            :dealer_uuid.sync="checklist.crm_partner_uuid"
            :initial_data="checklist.crm_partner"
            :select-other="selectOther"
            :select-first="true"
            :filter-by-scope="filterByScope"
            @change="handleDealerSelected"
          />
        </v-col>
      </v-row>
      <v-row
        v-if="dealerSelected !== 'other' && dealerSelected !== null"
        no-gutters
      >
        <v-col cols="10" class="ml-4">
          <task-assignee-selector
            :assignee-uuid.sync="checklist.assignee_email"
            :initial-data="checklist.assignee"
            :professional="checklist.crm_partner_uuid"
            @change="handleAssignee"
            :key="checklist.crm_partner_uuid"
            :filter-by-scope="filterByScope"
          ></task-assignee-selector>
          <!-- @unrecognizedAssignee="handleUnrecognizedAssignee" -->
        </v-col>
      </v-row>
      <v-row v-if="dealerSelected === 'other'" no-gutters>
        <v-col cols="10" class="ml-4">
          <v-text-field
            class="mt-0 pt-0"
            v-model="checklist.unrecognizedAssigneeEmail"
            color="primary"
            label="Enter E-mail address"
            autocomplete="new-password"
          />
        </v-col>
      </v-row>
    </div>

    <v-form class="mb-8 ml-4 mt-4">
      <v-row no-gutters v-for="(subtask, ind) in checklist.subtasks" :key="ind">
        <v-col cols="9">
          <v-checkbox
            disabled
            v-model="subtask.completed"
            color="primary"
            @change="saveSubtask('checkbox', subtask)"
            :label="subtask.description"
          ></v-checkbox
        ></v-col>
        <v-spacer></v-spacer>
        <v-col cols="1" class="pt-2 mr-4">
          <v-card-actions>
            <v-btn :disabled="disabled" icon @click="deleteSubtask(ind)">
              <v-icon color="red">mdi-delete</v-icon>
            </v-btn>
          </v-card-actions>
        </v-col>
        <v-row no-gutters>
          <v-col cols="9" class="ml-8">
            <v-textarea
              class="pt-0 mt-0 pb-0 mb-0"
              color="primary"
              v-if="subtask.active"
              v-model="subtask.comment"
              rows="1"
              label="Comment"
              auto-grow
              @change="saveSubtask('comment', subtask)"
              :disabled="disabled"
            >
            </v-textarea>
          </v-col>
        </v-row>
      </v-row>
      <v-row no-gutters class="mb-4">
        <v-col cols="11" class="pr-8">
          <v-textarea
            prepend-icon="mdi-checkbox-blank-outline"
            v-model="subtaskText"
            label="Enter new task description"
            rows="1"
            value=""
            auto-grow
            color="primary"
            @input="makeSubtask($event)"
            @blur="newSubtask"
            @keypress.enter="newSubtask"
            :disabled="disabled"
          ></v-textarea>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import ChecklistBoatDetails from "@/components/tasks/checklists/ChecklistBoatDetails.vue";
import ChecklistBoatItemSelector from "@/components/tasks/checklists/ChecklistBoatItemSelector.vue";
import ChecklistBoatSelector from "@/components/tasks/checklists/ChecklistBoatSelector";
import ChecklistToolbar from "@/components/tasks/checklists/ChecklistToolbar.vue";
import TaskDealerSelector from "@/components/tasks/TaskDealerSelector";
import TaskAssigneeSelector from "@/components/tasks/TaskAssigneeSelector";
import api from "@/utils/api";
export default {
  components: {
    ChecklistToolbar,
    ChecklistBoatSelector,
    TaskDealerSelector,
    TaskAssigneeSelector,
  },
  props: {
    servicingPartnerEnabled: {
      type: Boolean,
      default: Boolean,
    },
    checklist: {
      type: Object,
      default: Object,
    },
    checklistIndex: {
      type: Number,
      default: Number,
    },
    disabled: {
      type: Boolean,
      default: true,
    },
    showBoat: {
      type: Boolean,
      default: true,
    },
    professional: {
      type: String,
      default: String,
    },
    boatDisabled: {
      type: Boolean,
      default: Boolean,
    },
    toolbarDisabled: {
      type: Boolean,
      default: Boolean,
    },
    showServicingPartner: {
      type: Boolean,
      default: true,
    },
    filterByScope: {
      type: Object,
      default: Object,
    },
  },
  data: () => ({
    subtaskText: [],
    dealerSelected: null,
    selectOther: false,
  }),
  created() {
    if (
      typeof this.checklist.assignee !== "undefined" &&
      typeof this.checklist.assignee.is_external !== "undefined"
    ) {
      this.selectOther = true;
      this.checklist.unrecognizedAssigneeEmail = this.checklist.assignee.email;
    }
  },
  methods: {
    handleDealerSelected(dealer) {
      if (dealer === "other") {
        this.checklist.assignee_email = null;
        this.checklist.assignee = {};
      }
      this.dealerSelected = dealer;
      this.$emit("update:checklist", {
        checklist: this.checklist,
        index: this.checklistIndex,
      });
    },
    handleToolbarAction(event) {
      if (event.action === "remove") {
        this.$emit("delete:checklist", {
          checklist: this.checklist,
          index: this.checklistIndex,
        });
      }
    },
    deleteSubtask(index) {
      this.checklist.subtasks.splice(index, 1);
      this.$emit("update:checklist", {
        checklist: this.checklist,
        index: this.checklistIndex,
      });
    },
    makeSubtask(e) {
      let splitedText = [];
      splitedText = e.split(/\r\n|\r|\n|,/);
      this.subtaskText = splitedText.filter((elem) => elem !== "");
    },
    subtaskTemplate(description) {
      return {
        completed: null,
        comment: "",
        description: description,
      };
    },
    handleAssignee(email) {
      this.$emit("update:checklist", {
        checklist: this.checklist,
        index: this.checklistIndex,
      });
    },
    newSubtask(ind = 0) {
      if (this.subtaskText.length === 0) {
        return;
      }

      this.subtaskText.forEach((el, index) => {
        let template = this.subtaskTemplate(el);
        this.checklist.subtasks.push(template);
      });

      this.$emit("update:checklist", {
        checklist: this.checklist,
        index: this.checklistIndex,
      });
      this.subtaskText = [];
    },
    handleNewBoat(boat) {
      if (Object.keys(boat).length === 0) {
        this.checklist.crm_boat_uuid = null;
        this.checklist.crm_boat = null;
      } else {
        this.checklist.crm_boat_uuid = boat.uuid;
      }
      this.$emit("update:checklist", {
        checklist: this.checklist,
        index: this.checklistIndex,
      });
    },
  },
};
</script>

<style></style>
