<template>
  <v-container my-10>
    <v-layout align-center justify-center>
      <v-progress-circular
        :size="55"
        :width="4"
        indeterminate
        class="mt-12"
        v-if="loading"
        color="primary"
      ></v-progress-circular>
      <v-card v-else max-width="650px" width="650px">
        <v-card-title class="ml-4">
          <span class="headline" v-if="editView">Edit Checklist</span>
          <span class="headline" v-else>Inventory</span>

          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text class="pb-0">
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            v-on:keydown.enter.stop=""
          >
            <v-row>
              <v-col cols="12">
                <template v-for="(checklist, index) in editedItem.checklists">
                  <checklist-add-edit
                    :key="checklist.vueId"
                    :professional="editedItem.crm_partner_uuid"
                    :checklist-index="index"
                    :checklist="checklist"
                    @update:checklist="updateChecklist"
                    :disabled="checkFinishedTask"
                    :boat-disabled="true"
                    :show-boat="false"
                    :toolbar-disabled="true"
                    :show-servicing-partner="false"
                  ></checklist-add-edit>
                </template>
              </v-col>
            </v-row>

            <v-row class="mx-2" v-if="scopeOptions.length > 1">
              <v-col cols="12">
                <v-select
                  label="Visibility*"
                  v-model="scope"
                  :items="scopeOptions"
                  item-text="name"
                  item-value="uuid"
                  persistent-hint
                  :rules="scopeRule"
                  hint="Required"
                  return-object
                  @change="handleScopeChange"
                >
                </v-select>
              </v-col>
            </v-row>
            <!-- <v-row class="mx-2">
              <v-col cols="12">
                <boat-selector
                  :initial-boat="editedItem.checklists[0].crm_boat"
                  :show-boat-name="true"
                  label="Select Boat"
                  @change="handleBoatChange"
                  :filter-by-scope="dealership"
                ></boat-selector>
              </v-col>
            </v-row> -->

            <v-row class="mb-4 mr-2">
              <v-spacer></v-spacer>
              <template>
                <v-btn text color="primary" @click="addAttachment">
                  Add Attachment
                </v-btn>
                <input
                  ref="uploader"
                  class="d-none"
                  type="file"
                  @change="onFileChanged"
                  id="attachmentImport"
                />
              </template>
            </v-row>

            <v-row class="mb-2">
              <v-list-item
                v-for="(selectedFile, index) in selectedFiles"
                :key="index"
                class="mt-2"
              >
                <v-btn
                  text
                  class="text-none grey--text text--darken-1"
                  @click="openAttachment(selectedFile)"
                >
                  <v-icon class="mr-1">
                    mdi-file-document
                  </v-icon>
                  <!-- <span v-if="typeof selectedFile.name !== 'undefined'">
                    {{ selectedFile.name }}
                  </span> -->
                  <!-- <span v-else>
                    {{ selectedFile.title }}
                  </span> -->
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  icon
                  color="red"
                  class="mt-1 mr-2"
                  @click="removeAttachment(index)"
                >
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </v-list-item>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <template>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="close()">Cancel</v-btn>
            <v-btn color="primary" text class="mr-2" @click="saveTemplate"
              >Save</v-btn
            >
          </template>
        </v-card-actions>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
import api from "@/utils/api.js";
import snackbar from "@/utils/snackbar";
import { mapGetters, mapState } from "vuex";
import ChecklistAddEdit from "@/components/tasks/checklists/ChecklistAddEdit.vue";
// import BoatSelector from "@/components/tasks/BoatSelector.vue";

export default {
  components: {
    ChecklistAddEdit,
    // BoatSelector,
  },
  data: () => ({
    editView: false,
    loading: true,
    valid: true,
    scope: {},
    dealership: {},
    scopeRule: [(v) => !!v || "Scope is required"],
    uploadImageDialog: { active: false },
    attachmentData: { templateUuid: null, checklistUuid: null },
    checklistTypeOptions: [
      { text: "Check-in (Guest)", value: "GUEST_CHECKIN" },
      { text: "Check-out (Guest)", value: "GUEST_CHECKOUT" },
      { text: "Task (Team member)", value: null },
    ],
    crm_boat_uuid: null,
    checklistTypeSelected: null,
    editedItem: {
      name: "",
      checklists: [],
    },
    selectedFiles: [],
    filesToDelete: [],
    isSelecting: false,
  }),

  computed: {
    ...mapGetters(["getMe"]),
    getStatus() {
      return this.statusOptions.find(
        (item) => item.status === this.editedItem.status
      ).name;
    },
    checkFinishedTask() {
      return !!this.editedItem.completed;
    },
    getDuration() {
      let duration = this.durations.find(
        (item) => item.value === this.editedItem.duration
      );
      return typeof duration === "undefined"
        ? this.editedItem.duration
        : duration.duration;
    },

    modelPresent() {
      return !!this.editedItem.crm_boat;
    },
    scopeOptions() {
      return this.getMe.crm_groups;
    },
  },

  created() {
    this.getView();
    if (this.editView) {
      this.loadTemplate();
    } else {
      this.scope = this.scopeOptions.find((item) => {
        return item.uuid === "407193584eeb422a988c8750b1c16550";
      });
      this.editedItem.checklists = [
        this.setNewBoatTemplate(
          {
            uuid: "83452be14f4e4dd7a8aaf6a595d7a04a",
            model: {
              uuid: "230d66f60b7f4953a8535bb5338fc42a",
              brand_name: "Hanse",
              model: "41",
            },
            boat_name: "Biopsea",
            pretty_name: "41",
            device_serial: "Test Device 7855",
            slot_number: null,
            ce_number: null,
            is_user_present: true,
          },
          [
            {
              uuid: "8347acc40ca1413fb89512f26e80b449",
              description: "cockpit table",
            },
            {
              uuid: "5e5a6caf8bbe49bfa443cf0762b0e933",
              description: "wind direction indicator",
            },
            {
              uuid: "aa4cbb1bb00e45409391c4c4a3503c11",
              description: "steering wheel with compass",
            },
            {
              uuid: "be6f21b246fd4a9092a0ca42ea77b2ff",
              description: "speedometer and echo sounder",
            },
            {
              uuid: "d7c32b7108b442d09a4fd928674fae48",
              description: "bilge pump",
            },
            {
              uuid: "8a131058e4f243c5ad7a2d9fd1594e74",
              description: "Gangway board",
            },
            {
              uuid: "2b1a1b5898af49bb9971c603b3d175b9",
              description: "life buoy with light",
            },
            {
              uuid: "83b0b862fdba41cfaa5a570aa7d1e3a0",
              description: "GPS+Charterplotter",
            },
            {
              uuid: "823f8cb8d6f244dc8e6c6ce5c82c023b",
              description: "main sail",
            },
            {
              uuid: "3e39f01f2a23407e9276fe3070e31757",
              description: "roll genoa",
            },
            {
              uuid: "13396c79b7e742d183bb9a47df9a5f0d",
              description: "flag",
            },
            {
              uuid: "96fe226ee4424c768cfb409d7786814d",
              description: "boat hook",
            },
            {
              uuid: "90a42a61c4794abaaf2d69c3f2371dc6",
              description: "ultra sound",
            },
          ],
          "Standard equipment"
        ),
        this.setNewBoatTemplate(
          {
            uuid: "83452be14f4e4dd7a8aaf6a595d7a04a",
            model: {
              uuid: "230d66f60b7f4953a8535bb5338fc42a",
              brand_name: "Hanse",
              model: "41",
            },
            boat_name: "Biopsea",
            pretty_name: "41",
            device_serial: "Test Device 7855",
            slot_number: null,
            ce_number: null,
            is_user_present: true,
          },
          [
            {
              uuid: "65a26f15b3be4c11968f538c1bf36123",
              description: "dinghy",
            },
            {
              uuid: "65a26f15b3be4c11968f538c1bf36321",
              description: "outboard",
            },
            {
              uuid: "65a26f15b3be4c11968f538c1bf36333",
              description: "spinnaker pole",
            },
          ],
          "Optional equipment"
        ),
      ];

      if (this.scope !== null) {
        this.setDealershipFromScope(this.scope);
      }

      this.loading = false;
    }
  },
  watch: {
    $route(newValue, oldValue) {
      this.getView();
    },
  },

  methods: {
    setDealershipFromScope(scope) {
      this.dealership = this.getMe.dealerships.find((item) => {
        return item.partner_group.uuid === scope.uuid;
      });
    },
    handleScopeChange(event) {
      this.scope = event;
      this.setDealershipFromScope(event);
    },
    addAttachment() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );
      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      this.selectedFiles.push(e.target.files[0]);
      document.getElementById("attachmentImport").value = "";
    },

    openAttachment(selectedFile) {
      if (typeof selectedFile.file_link !== "undefined") {
        window.open(selectedFile.file_link, "_blank");
        return false;
      }

      var reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onload = function() {
        let w = window.open("about:blank");
        let image = new Image();
        image.src = reader.result;
        setTimeout(function() {
          w.document.write(image.outerHTML);
        }, 0);
      };
    },

    removeAttachment(index) {
      this.filesToDelete.push(this.selectedFiles[index].uuid);
      this.selectedFiles.splice(index, 1);
    },

    handleCloseAttachmentDialog() {
      this.uploadImageDialog.active = false;
    },
    handleUploadImageDialog(event) {
      this.editedItem.checklists[0].attachments = event;
      this.pendingAttchements = event;
      this.uploadImageDialog.active = false;
    },
    handleBoatChange(boat) {
      if (boat === null) {
        this.crm_boat_uuid = null;
        return;
      }
      this.crm_boat_uuid = boat.uuid;
    },

    updateChecklist(object) {
      const { checklist, index } = object;
      this.$set(this.editedItem.checklists, index, checklist);
    },

    setNewBoatTemplate(boat = null, subtasks = [], subtitle) {
      return {
        vueId: this.editedItem.checklists.length,
        crm_boat: boat,
        subtasks: subtasks,
        crm_boat_uuid: null,
        name: subtitle,
      };
    },

    getView() {
      this.editView = this.$route.name === "TasksTemplatesEdit";
    },

    loadTemplate() {
      return new Promise((resolve, reject) => {
        api.getTemlate(this.$route.params.id).then((resp) => {
          if (resp.data.checklists.length === 0) {
            resp.data.checklists.push({ name: "Checklist 1" });
          }

          this.scope = resp.data.crm_group;
          this.setDealershipFromScope(this.scope);
          this.selectedFiles = resp.data.checklists[0].attachments;
          this.editedItem = resp.data;
          this.checklistTypeSelected = this.editedItem.checklists[0].checklist_type;
          this.loading = false;
          resolve();
        });
      });
    },

    close() {
      this.$router.push({
        name: "TasksTemplate",
      });
    },

    previewTask(taskUuid) {
      this.$router
        .push({
          name: "TasksTemplate",
        })
        .then((resp) => {
          this.loading = false;
        });
    },

    uploadAttachments(templateUuid, checklistUuid) {
      let promises = [];
      this.selectedFiles.forEach((element) => {
        promises.push(
          new Promise(function(resolve, reject) {
            let formData = new FormData();
            formData.append("file", element);
            formData.append("title", element.name);
            api
              .templateAttachment(templateUuid, formData, {
                checklist_uuid: checklistUuid,
              })
              .then((resp) => {
                resolve();
              });
          })
        );
      });
      return promises;
    },

    saveTemplate() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        //this.editedItem.crm_group_uuid = this.getMe.crm_groups[0].uuid;
        this.editedItem.crm_group_uuid = this.scope.uuid;
        this.editedItem.name = this.editedItem.checklists[0].name;
        this.editedItem.checklists[0].crm_boat_uuid = this.crm_boat_uuid;
        this.editedItem.checklists[0].checklist_type = this.checklistTypeSelected;

        if (this.editView) {
          this.editedItem.checklists[0].delete_attachment_uuids = this.filesToDelete;
          //false parameter is here for disabling error handling
          api
            .updateTemplate(this.editedItem.uuid, this.editedItem, false)
            .then((resp) => {
              this.selectedFiles = this.selectedFiles.filter((element) => {
                return typeof element.file_link === "undefined";
              });

              Promise.all(
                this.uploadAttachments(
                  resp.data.uuid,
                  resp.data.checklists[0].uuid
                )
              ).then((values) => {
                snackbar.success("Successfully saved");
                this.previewTask(resp.data.uuid);
              });
            })
            .catch((err) => {
              this.handleError(err.response);
              this.loading = false;
            });
        } else {
          //false parameter is here for disabling error handling
          api
            .createTemplate(this.editedItem, false)
            .then((resp) => {
              Promise.all(
                this.uploadAttachments(
                  resp.data.uuid,
                  resp.data.checklists[0].uuid
                )
              ).then((values) => {
                snackbar.success("Successfully saved");
                this.previewTask(resp.data.uuid);
              });
            })
            .catch((err) => {
              this.handleError(err.response);
              this.loading = false;
            });
        }
      } else {
        snackbar.error("Please fill required fields");
      }
    },

    handleError(err) {
      if (err) {
        snackbar.error(err.response);
      } else {
        snackbar.error();
      }
    },
  },
};
</script>
<style scoped></style>
