<template>
  <div>
    <v-autocomplete
      label="Select Boat"
      v-model="boat"
      :items="boatOptions"
      :menu-props="{ maxHeight: '400' }"
      @input.native="getBoats($event, $data)"
      :search-input.sync="boatSearch"
      persistent-hint
      color="primary"
      clearable
      autocomplete="new-password"
      hide-selected
      @change="handleChange"
      :disabled="disabled"
      :item-text="formattedBoatName"
    >
      <template v-slot:item="{ item }">
        {{ formattedBoatName(item) }}
      </template>
      <template v-slot:selection="{ item }">
        {{ formattedBoatName(item) }}
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import api from "@/utils/api";
import debounce from "@/plugins/debounce";

export default {
  props: {
    checklist: {
      type: Object,
      default: Object,
    },
    disabled: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    boatOptions: [],
    boatSearch: "",
    boat: null,
    loading: true,
  }),

  created() {
    this.getBoats().then((resp) => {
      if (this.checklist.crm_boat_uuid !== null) {
        let crmBoat = this.boatOptions.find(
          (boat) => boat.uuid === this.checklist.crm_boat_uuid
        );
        if (typeof crmBoat !== "undefined") {
          this.boat = crmBoat;
        }
      }

      if (
        this.checklist.crm_boat !== null &&
        Object.keys(this.checklist.crm_boat).length !== 0
      ) {
        this.boatOptions.push(this.checklist.crm_boat);
        this.boat = this.checklist.crm_boat;
      }
    });
  },
  methods: {
    formattedBoatName: (item) => {
      if (item) {
        return `${item.model.brand_name} ${item.model.model} #${item.slot_number}`;
      } else {
        return;
      }
    },
    handleChange(value = {}) {
      this.$emit("new:boat", value);
    },
    getBoats(clearOptions = false) {
      return new Promise((resolve, reject) => {
        if (clearOptions) {
          this.boatOptions = [];
        }
        this.boatSearch = this.boatSearch ? this.boatSearch : "";
        api
          .boatsAutocomplete({
            autocomplete: true,
            search: this.boatSearch,
          })
          .then((resp) => {
            resp.data.results.forEach((element) => {
              if (
                !this.boatOptions
                  .map((boat) => boat.uuid)
                  .includes(element.uuid)
              ) {
                this.boatOptions.push(element);
              }
            });
            this.loading = false;
            resolve();
          });
      });
    },
  },
};
</script>
