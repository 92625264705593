<template>
  <v-autocomplete
    v-model="dealerUUID"
    :items="dealerOptions"
    item-value="uuid"
    item-text="name"
    :menu-props="{ maxHeight: '400' }"
    :search-input.sync="dealerSearch"
    :label="dealerLabel"
    persistent-hint
    color="primary"
    clearable
    autocomplete="new-password"
  />
</template>

<script>
import api from "@/utils/api";
import debounce from "@/plugins/debounce";

export default {
  name: "DealerSelector",
  props: [
    "dealer_uuid",
    "initial_data",
    "label",
    "selectFirst",
    "tasks",
    "selectOther",
    "filterByScope",
  ],
  data: () => ({
    dealerOptions: [{ name: "Other", uuid: "other" }],
    dealerSearch: "",
    readOnly: false,
  }),
  created() {
    if (this.initial_data && typeof this.initial_data.uuid !== "undefined") {
      this.dealerOptions.push(this.initial_data);
      this.dealerUUID = this.initial_data.uuid;
    }

    if (this.selectOther) {
      this.dealerUUID = "other";
    }

    this.dealerOptions = [
      ...this.dealerOptions,
      { name: "Other", uuid: "other" },
      this.filterByScope,
    ];
    // this.loadDealers(null, this);
  },

  watch: {
    filterByScope(newVal, oldVal) {
      this.dealerOptions = [{ name: "Other", uuid: "other" }, newVal];
    },
  },

  computed: {
    dealerLabel: function() {
      return this.label;
    },
    dealerUUID: {
      get: function() {
        return this.dealer_uuid;
      },
      set: function(value) {
        if (typeof value !== "undefined") {
          this.$emit("update:dealer_uuid", value);
          this.$emit("change", value);
        } else {
          this.$emit("update:dealer_uuid", null);
          this.$emit("change", null);
        }
      },
    },
  },
  methods: {
    loadDealers: function() {
      var self = this;
      self.dealerSearch = self.dealerSearch ? self.dealerSearch : "";
      let params = {
        search: self.dealerSearch,
      };

      if (typeof self.tasks !== "undefined" && self.tasks === true) {
        params.purpose = "task_assignment";
      }

      api.dealersAutocomplete(params).then((resp) => {
        resp.data.results.forEach((element) => {
          if (!self.dealerOptions.find((el) => el.uuid == element.uuid)) {
            self.dealerOptions.push(element);
          }
        });

        if (this.selectFirst && this.dealerOptions.length === 1) {
          this.dealerUUID = this.dealerOptions[0].uuid;
          this.readOnly = true;
        }
      });
    },
    debounceDealers: debounce((event, self) => {
      this.loadDealers();
    }, 300),
  },
};
</script>
