<template>
  <v-row no-gutters>
    <v-col cols="11">
      <v-list three-line v-if="boat !== null">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title v-if="boat.model">
              {{ boat.model.brand_name }} {{ boat.model.model }}
              <span v-if="boat.slot_number"> #{{ boat.slot_number }} </span>
            </v-list-item-title>
            <v-list-item-subtitle
              >Serial number:
              <span v-if="boat.device_serial">{{
                boat.device_serial
              }}</span></v-list-item-subtitle
            >
            <v-list-item-subtitle v-if="boat.ce_number"
              >HIN: {{ boat.ce_number }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <!-- <v-list single-line v-else>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              No boat
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list> -->
    </v-col>
    <v-col cols="1" class="mt-2 float-right">
      <checklist-toolbar :preview="preview"></checklist-toolbar>
    </v-col>
  </v-row>
</template>

<script>
import ChecklistToolbar from "@/components/tasks/checklists/ChecklistToolbar.vue";
export default {
  components: {
    ChecklistToolbar,
  },
  props: {
    boat: {},
    preview: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({}),
  created() {},
  computed: {},
};
</script>

<style></style>
