<template>
  <v-menu bottom right v-if="preview">
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="mt-4" icon v-bind="attrs" v-on="on">
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>

    <v-list>
      <v-list-item v-for="(item, i) in actions" :key="i">
        <v-btn text @click="handleAction(item)"
          ><v-icon class="mr-2" :color="item.color">{{ item.icon }}</v-icon
          >{{ item.text }}</v-btn
        >
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    preview: { type: Boolean, default: false },
  },
  data: () => ({
    actions: [
      // {
      //   text: "Move to another task",
      //   icon: "mdi-cursor-move",
      //   color: "sentinelBlueLight",
      //   action: "move",
      // },
      { text: "Remove", icon: "mdi-delete", color: "red", action: "remove" },
    ],
  }),
  computed: {},
  methods: {
    handleAction(event) {
      this.$emit("action", event);
    },
  },
};
</script>

<style></style>
