<template>
  <v-autocomplete
    label="Select Boat Item"
    v-model="boatItem"
    :items="boatItemOptions"
    :menu-props="{ maxHeight: '400' }"
    @input.native="loadBoatItemsSearch($event, $data, boatUuid)"
    :search-input.sync="boatItemSearch"
    persistent-hint
    color="primary"
    clearable
    autocomplete="new-password"
    @change="handleChange(boatItem)"
    :item-text="formattedItemName"
    hide-no-data
    hide-selected
    return-object
  >
    <template v-slot:item="{ item }">
      <span>
        {{ formattedItemName(item) }}
      </span>
      <span v-if="item.sku" class="ml-1">({{ item.sku }}) </span>
    </template>
  </v-autocomplete>
</template>

<script>
import api from "@/utils/api";
import debounce from "@/plugins/debounce";
export default {
  props: {
    boatUuid: {
      type: String,
      default: String,
    },
  },
  data: () => ({
    boatItemOptions: [],
    boatItemSearch: "",
    boatItem: null,
  }),
  created() {
    this.loadBoatItemsSearch(null, this, this.boatUuid);
  },
  methods: {
    handleChange(value) {
      this.$emit("new:item", value);
      this.$nextTick(() => {
        this.boatItem = null;
      });
    },
    formattedItemName(item) {
      return item.vendor ? item.vendor.name + " " + item.name : item.name;
    },
    loadBoatItemsSearch: debounce((event, self, boatUuid) => {
      self.boatItemSearch = self.boatItemSearch ? self.boatItemSearch : "";
      api
        .getBoatItems(boatUuid, { search: self.boatItemSearch })
        .then((resp) => {
          resp.data.results.forEach((element) => {
            self.boatItemOptions.push(element);
          });
        });
    }, 300),
  },
};
</script>
